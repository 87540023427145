.vaccine-list-container {
    margin: 20px auto; /* Ortalamak için auto değeri kullanılıyor */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 1100px; /* Sayfanın maksimum genişliğini belirliyoruz */
}

.vaccine-list-container h2 {
    color: #333;
    margin-bottom: 15px;
}

.vaccine-list-container table {
    width: 100%;
    border-collapse: collapse;
}

.vaccine-list-container th,
.vaccine-list-container td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #eee;
}

.vaccine-list-container th {
    background-color: #f5f5f5;
}

.vaccine-list-container tr:hover {
    background-color: #eef;
}

.vaccine-list-container td {
    color: #555;
}

.add-vaccine-form {
    margin-bottom: 30px;
}

.add-vaccine-form input,
.add-vaccine-form button {
    display: block;
    width: calc(100% - 22px); /* Adjusted width to account for border and padding */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.add-vaccine-form button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border: none;
}

.add-vaccine-form button:hover {
    background-color: #45a049;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table,
th,
td {
    border: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: left;
}

td {
    padding: 10px;
    text-align: left;
}

button {
    background-color: #008CBA;
    color: white;
    padding: 5px 10px;
    margin: 4px 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #007B8A;
}