.report-list {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  
  .report-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  label, input {
    margin: 10px 0;
    display: block;
  }
  
  button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    opacity: 0.8;
  }
  